<template>
    <section class="invoice-preview-wrapper">
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="orderData === undefined">
            <h4 class="alert-heading">Error fetching order data</h4>
            <div class="alert-body">
                No order found with this order id. Check
                <b-link class="alert-link" :to="{ name: 'ecommerce-order-list' }"> Order List </b-link>
                for other invoices.
            </div>
        </b-alert>
        <b-col>
            <div v-if="isFetching" class="h-100 d-flex justify-content-center align-items-center">
                <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
            </div>
        </b-col>

        <b-row v-if="orderData" class="invoice-preview">
            <!-- Col: Left (Invoice Container) -->
            <b-col cols="12" xl="9" md="8">
                <b-card no-body class="invoice-preview-card">
                    <!-- Header -->
                    <b-card-body class="invoice-padding pb-0">
                        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                            <!-- Header: Left Content -->
                            <div>
                                <div class="logo-wrapper">
                                    <b-img :src="orderData.logoUrl" width="200px" />
                                </div>
                                <div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td class="pr-1">Total Due:</td>
                                                <td>
                                                    <span class="font-weight-bold">{{
                                                        orderData.total | formatCurrency
                                                    }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="pr-1">Status:</td>
                                                <td>{{ resolveOrderStatus(orderData.status) }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pr-1">Payment Method:</td>
                                                <td>{{ orderData.paymentMethodTitle }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pr-1">Customer IP:</td>
                                                <td>{{ orderData.customerIp }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <!-- Header: Right Content -->
                            <div class="mt-md-0 mt-2">
                                <h4 class="invoice-title">
                                    Order
                                    <span class="invoice-number">#{{ orderData.originId }}</span>
                                </h4>
                                <div
                                    v-if="Array.isArray(orderRepresentatives) && orderRepresentatives.length"
                                    class="flex-column align-items-center justify-content-start invoice-date-wrapper"
                                >
                                    <div class="container pl-0 ml-0 text-left">
                                        <h5>Representatives</h5>
                                    </div>
                                    <div
                                        v-for="representative in orderRepresentatives"
                                        :key="representative.id"
                                        class="container pl-0 ml-0 text-left"
                                    >
                                        <span>{{ representative.name }}</span>
                                    </div>
                                </div>
                                <hr v-if="Array.isArray(orderRepresentatives) && orderRepresentatives.length" />
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">Order Date:</p>
                                    <p class="invoice-date">
                                        {{ orderData.originCreateDate | formatDateTime }}
                                    </p>
                                </div>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">Update Date:</p>
                                    <p class="invoice-date">
                                        {{ orderData.originUpdateDate | formatDateTime }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </b-card-body>

                    <!-- Spacer -->
                    <hr class="invoice-spacing" />

                    <!-- Invoice Client & Payment Details -->
                    <b-card-body v-if="orderData" class="invoice-padding pt-0">
                        <b-row class="invoice-spacing">
                            <!-- Col: Invoice To -->
                            <b-col cols="12" xl="6" class="p-0">
                                <div>
                                    <h6>Billing:</h6>
                                    <h6 class="mb-25">
                                        {{ orderData.billing.first_name }} {{ orderData.billing.last_name }}
                                    </h6>
                                    <p class="card-text mb-25">
                                        {{ orderData.billing.email }}
                                    </p>
                                    <p class="card-text mb-25">
                                        {{ orderData.billing.address_1 }}
                                    </p>
                                    <p :v-if="orderData.billing.address_2" class="card-text mb-25">
                                        {{ orderData.billing.address_2 }}
                                    </p>
                                    <p class="card-text mb-25">
                                        {{ orderData.billing.city }}, {{ orderData.billing.state }}
                                        {{ orderData.billing.postcode }},
                                        {{ orderData.billing.country }}
                                    </p>
                                    <p class="card-text mb-0">
                                        {{ orderData.billing.phone }}
                                    </p>
                                </div>
                            </b-col>

                            <!-- Col: Payment Details -->
                            <b-col xl="6" cols="12" class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end">
                                <div>
                                    <div>
                                        <h6>Shipping:</h6>
                                        <p class="card-text mb-25">
                                            {{ orderData.shipping.address_1 }}
                                        </p>
                                        <p :v-if="orderData.shipping.address_2" class="card-text mb-25">
                                            {{ orderData.shipping.address_2 }}
                                        </p>
                                        <p class="card-text mb-25">
                                            {{ orderData.shipping.city }}, {{ orderData.shipping.state }}
                                            {{ orderData.shipping.postcode }},
                                            {{ orderData.shipping.country }}
                                        </p>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>

                    <!-- Invoice Description: Table -->
                    <b-table-lite
                        responsive
                        :items="orderData.lineItems"
                        :fields="[{ key: 'photoURL', label: 'Image' }, 'name', 'price', 'quantity', 'total']"
                    >
                        <template #cell(photoURL)="data">
                            <b-img-lazy :src="data.item.photoURL" fluid width="100px" height="auto" />
                        </template>

                        <template #cell(name)="data">
                            <b-card-text class="font-weight-bold mb-25">
                                {{ data.item.name }}
                            </b-card-text>
                            <b-card-text class="text-nowrap">
                                <!-- {{ data.item.taskDescription }} BURAYA SİPARİŞ AÇIKLAMASI GELEBİLİR -->
                            </b-card-text>
                        </template>

                        <template #cell(price)="data">
                            <span class="text-nowrap">
                                {{ data.value | formatCurrency }}
                            </span>
                        </template>

                        <template #cell(total)="data">
                            <span class="text-nowrap">
                                {{ data.value | formatCurrency }}
                            </span>
                        </template>
                    </b-table-lite>

                    <!-- Invoice Description: Total -->
                    <b-card-body class="invoice-padding pb-1 d-flex justify-content-end">
                        <div class="w-100 text-right">
                            <div v-for="fee in orderData.fees" :key="fee.id" class="invoice-total-item">
                                <p class="font-weight-bolder">Fee: {{ fee.name }}</p>
                                <p>
                                    {{ fee.total | formatCurrency }}
                                </p>
                            </div>
                            <hr class="my-50" />
                            <div class="invoice-total-item pb-1">
                                <p class="invoice-total-title font-weight-bolder">Total:</p>
                                <p class="invoice-total-amount">
                                    {{ orderData.total | formatCurrency }}
                                </p>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>

                <b-card v-if="orderActivities" title="Activities">
                    <order-activities :order-activities="orderActivities" @onOrderActivitiesLoad="syncOrderNotes" />
                </b-card>
            </b-col>

            <!-- Right Col: Card -->
            <b-col cols="12" md="4" xl="3" class="invoice-actions">
                <!-- Order Source -->
                <b-card>
                    <b-form-group label="Order Source" label-for="order-source">
                        <v-select
                            v-model="currentSource"
                            :options="orderSources"
                            input-id="order-source"
                            class="payment-selector"
                            :clearable="false"
                            @input="updateSource"
                        />
                    </b-form-group>
                </b-card>
                <b-card>
                    <b-button
                        v-ripple.400="'rgba(186,191,199,0.15'"
                        :variant="representativeState.variant"
                        class="mb-75"
                        block
                        @click="updateRepresentative(orderRepresentatives)"
                    >
                        {{ representativeState.text }}
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        class="mb-75"
                        block
                        @click="printOrderDetails"
                    >
                        Print
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-primary"
                        block
                        :to="{
                            name: 'ecommerce-order-edit',
                            params: {
                                orderId: $route.params.id,
                            },
                        }"
                    >
                        Edit
                    </b-button>
                </b-card>

                <b-card v-if="orderMessages.length" title="Messages">
                    <order-messages :order-messages="orderMessages" />
                </b-card>

                <b-card title="Add Message">
                    <order-message-box @refetch-messages="refetchMessages" />
                </b-card>
                <!-- <b-card title="Tracking Number">
          <div class="d-flex align-items-center mt-2">
            584748 5684 245 87
          </div> -->
                <!-- {{ trackingNumber}} -->
                <!-- <b-form-group class="mt-2">
            <b-form-input
              v-model="trackingNumber"
              placeholder=" Enter Tracking Number: "
              class="mb-0"
            /> -->

                <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              class="mt-1"
            > -->
                <!--              @click="updateTrackingNumber"
              Set Tracking Number
            </b-button>
             v-model="orderData.customerNote"
          </b-form-group>
        </b-card>-->
            </b-col>
        </b-row>
    </section>
</template>

<script>
import { onUnmounted, ref, computed } from "@vue/composition-api";
import { getUserData } from "@/auth/utils";
import {
    BAlert,
    BButton,
    BCard,
    BSpinner,
    BCardBody,
    BCardText,
    BCol,
    BFormGroup,
    // BFormInput,
    BImg,
    BImgLazy,
    BLink,
    BRow,
    BTableLite,
    VBToggle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";

import orderStoreModule from "../orderStoreModule";
import OrderMessages from "./OrderMessages.vue";
import OrderMessageBox from "./OrderMessageBox.vue";
import OrderActivities from "./OrderActivities.vue";

export default {
    directives: {
        Ripple,
        "b-toggle": VBToggle,
    },
    components: {
        BRow,
        BCol,
        BCard,
        BSpinner,
        BCardBody,
        BTableLite,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BFormGroup,
        // BFormInput,
        BImg,
        BImgLazy,
        OrderMessages,
        OrderActivities,
        OrderMessageBox,
        vSelect,
    },
    setup() {
        const userId = getUserData().id;
        const toast = useToast();
        const trackingNumber = ref("");
        const orderData = ref(null);
        const orderMessages = ref(null);
        const orderActivities = ref(null);
        const currentSource = ref(null);
        const orderRepresentatives = ref([]);
        const isFetching = ref(true);

        const representativeState = computed(() => {
            const userIdExists = orderRepresentatives.value.some(representative => representative.id === userId);

            if (userIdExists) {
                return {
                    variant: "danger",
                    text: "Remove Representative",
                    exists: true,
                };
            } else {
                return {
                    variant: "success",
                    text: "Assign Representative",
                    exists: false,
                };
            }
        });

        const updateRepresentative = () => {
            if (representativeState.value.exists) {
                store
                    .dispatch("ecommerce-order/removeRepresentative", orderData.value.id)
                    .then(() => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: "Representative Removed.",
                                icon: "CheckCircleIcon",
                                variant: "success",
                            },
                        });
                        // remove current user from order representatives
                        orderRepresentatives.value = orderRepresentatives.value.filter(
                            representative => representative.id !== userId,
                        );
                    })
                    .catch(() => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: "Error removing representative.",
                                icon: "AlertTriangleIcon",
                                variant: "danger",
                            },
                        });
                    });
            } else {
                store
                    .dispatch("ecommerce-order/assignRepresentative", orderData.value.id)
                    .then(() => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: "Representative Assigned.",
                                icon: "CheckCircleIcon",
                                variant: "success",
                            },
                        });
                    })
                    .then(() => refetchRepresentatives())
                    .catch(() => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: "Error assigning representative.",
                                icon: "AlertTriangleIcon",
                                variant: "danger",
                            },
                        });
                    });
            }
        };
        // Invoice Description
        // ? Your real data will contain this information

        const ECOMMERCE_ORDER_STORE_MODULE_NAME = "ecommerce-order";

        // Register module
        if (!store.hasModule(ECOMMERCE_ORDER_STORE_MODULE_NAME)) {
            store.registerModule(ECOMMERCE_ORDER_STORE_MODULE_NAME, orderStoreModule);
        }

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(ECOMMERCE_ORDER_STORE_MODULE_NAME)) {
                store.unregisterModule(ECOMMERCE_ORDER_STORE_MODULE_NAME);
            }
        });

        const printOrderDetails = () => {
            window.print();
        };

        const resolveOrderStatus = status => {
            switch (status) {
                case "on-hold":
                    return "On Hold";
                case "cancelled":
                    return "Cancelled";
                case "processing":
                    return "Processing";
                case "delivered":
                    return "Delivered";
                case "completed":
                    return "Completed";
                case "shipped":
                    return "Shipped";
                case "partial-shipped":
                    return "Partial Shipped";
                case "pending":
                    return "Pending";
                default:
                    return "Unknown";
            }
        };
        const syncOrderNotes = async () => {
            try {
                await store.dispatch("ecommerce-order/syncActivities", {
                    id: router.currentRoute.params.id,
                });
                refetchActivities();
            } catch {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error syncing order activities.",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            }
        };

        const refetchRepresentatives = () => {
            store
                .dispatch("ecommerce-order/fetchOrder", {
                    id: router.currentRoute.params.id,
                })
                .then(res => {
                    orderRepresentatives.value = res.data.representatives;
                });
        };

        const refetchMessages = () => {
            store
                .dispatch("ecommerce-order/fetchOrder", {
                    id: router.currentRoute.params.id,
                })
                .then(response => {
                    orderMessages.value = response.data.messages;
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        orderData.value = undefined;
                    }
                });
        };

        const refetchActivities = () => {
            store
                .dispatch("ecommerce-order/fetchOrder", {
                    id: router.currentRoute.params.id,
                })
                .then(res => {
                    orderActivities.value = res.data.activities;
                })
                .catch(err => {
                    if (err.response.status === 404) {
                        orderData.value = undefined;
                    }
                });
        };

        const getOrderDetails = async () => {
            await store
                .dispatch("ecommerce-order/fetchOrder", {
                    id: router.currentRoute.params.id,
                })
                .then(response => {
                    orderData.value = response.data.order;
                    orderMessages.value = response.data.messages;
                    orderActivities.value = response.data.activities;
                    orderRepresentatives.value = response.data.representatives;
                    currentSource.value = response.data.order.source;
                    isFetching.value = false;
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        orderData.value = undefined;
                    }
                });
        };

        getOrderDetails();

        const orderSources = ["Instagram", "Whatsapp", "Email", "Youtube", "Ads"];

        const updateSource = () => {
            store
                .dispatch("ecommerce-order/updateSource", {
                    orderId: router.currentRoute.params.id,
                    source: currentSource.value,
                })
                .then(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Order source successfully updated",
                            icon: "CheckCircleIcon",
                            variant: "success",
                        },
                    });
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Error updating order source",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                });
        };

        // const updateTrackingNumber = () => {
        //     store.dispatch("ecommerce-order/setTrackingNumber", {
        //         trackingNumber : trackingNumber
        //     })
        //         .then(() => {
        //             toast( {
        //                 component: ToastificationContent,
        //                 props: {
        //                     title: "Tracking number successfully updated",
        //                     icon: "CheckCircleIcon",
        //                     variant: "success",
        //                 },
        //             });
        //         })
        //         .catch(() => {
        //             toast({
        //                 component: ToastificationContent,
        //                 props: {
        //                     title: "Error updating tracking number",
        //                     icon: "AlertTriangleIcon",
        //                     variant: "danger",
        //                 },
        //             });
        //         });
        // };

        return {
            orderData,
            orderMessages,
            orderActivities,
            syncOrderNotes,
            getOrderDetails,
            trackingNumber,
            refetchMessages,
            orderRepresentatives,
            orderSources,
            updateRepresentative,
            representativeState,
            resolveOrderStatus,
            currentSource,
            updateSource,
            isFetching,
            refetchRepresentatives,
            printOrderDetails,
            refetchActivities,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
    // Global Styles
    body {
        background-color: transparent !important;
    }
    nav.header-navbar {
        display: none;
    }
    .main-menu {
        display: none;
    }
    .header-navbar-shadow {
        display: none !important;
    }
    .content.app-content {
        margin-left: 0;
        padding-top: 2rem !important;
    }
    footer.footer {
        display: none;
    }
    .card {
        background-color: transparent;
        box-shadow: none;
    }
    .customizer-toggle {
        display: none !important;
    }

    // Invoice Specific Styles
    .invoice-preview-wrapper {
        .row.invoice-preview {
            .col-md-8 {
                max-width: 100%;
                flex-grow: 1;
            }

            .invoice-preview-card {
                .card-body:nth-of-type(2) {
                    .row {
                        > .col-12 {
                            max-width: 50% !important;
                        }

                        .col-12:nth-child(2) {
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-end;
                            margin-top: 0 !important;
                        }
                    }
                }
            }
        }

        // Action Right Col
        .invoice-actions {
            display: none;
        }
    }

    .invoice-total-wrapper {
        max-width: 18rem !important;
    }
}
</style>
