<template>
    <div
        v-if="!orderActivities.length && Array.isArray(orderActivities)"
        class="h-100 d-flex justify-content-center align-items-center"
    >
        <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
    </div>
    <app-timeline v-else>
        <app-timeline-item
            v-for="activity in orderActivities"
            :key="activity.id"
            :title="activity.author"
            :subtitle="activity.note"
            :time="String(activity.originCreateDate) | formatDate"
            variant="primary"
        />
    </app-timeline>
</template>

<script>
import Ripple from "vue-ripple-directive";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { BSpinner } from "bootstrap-vue";

export default {
    emits: ["onOrderActivitiesLoad"],
    components: {
        AppTimeline,
        AppTimelineItem,
        BSpinner,
    },
    directives: {
        Ripple,
    },
    props: {
        orderActivities: {
            type: Array,
            default: () => [],
        },
    },
    mounted() {
        this.$emit("onOrderActivitiesLoad");
    },
};
</script>
