import { render, staticRenderFns } from "./OrderActivities.vue?vue&type=template&id=2f5c53dd&"
import script from "./OrderActivities.vue?vue&type=script&lang=js&"
export * from "./OrderActivities.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports