<template>
    <app-timeline>
        <app-timeline-item
            v-for="message in orderMessages"
            :key="message.id"
            :title="message.user"
            :subtitle="message.message"
            :time="String(message.createDate) | formatDate"
            :variant="message.status"
        />
    </app-timeline>
</template>

<script>
import Ripple from "vue-ripple-directive";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
    components: {
        AppTimeline,
        AppTimelineItem,
    },
    directives: {
        Ripple,
    },
    props: {
        orderMessages: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
