<template>
    <div>
        <b-form-textarea
            id="textarea-default"
            v-model="messageData.message"
            placeholder="Add your message..."
            rows="3"
            class="mb-75"
        />
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary" @click="addMessage">
            Send Message
        </b-button>
    </div>
</template>

<script>
import { BFormTextarea, BButton } from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";

import store from "@/store";
import router from "@/router";

export default {
    components: {
        BFormTextarea,
        BButton,
    },
    directives: {
        Ripple,
    },
    setup(props, { emit }) {
        const blankMessageData = {
            orderId: router.currentRoute.params.id,
            message: "",
        };

        const messageData = ref(JSON.parse(JSON.stringify(blankMessageData)));

        const addMessage = () => {
            store.dispatch("ecommerce-order/addMessage", messageData.value).then(() => {
                messageData.value.message = "";
                emit("refetch-messages");
            });
        };

        return {
            messageData,
            addMessage,
        };
    },
};
</script>
